import {
  addToCartAnalytics,
  quickAddToCartClick,
  quickAddToCartLocation,
} from './component/add-to-cart.listener.analytics';
import { addToQuote } from './component/add-to-quote.listener.analytics';
import { aisleBay } from './component/aisle-bay.listener.analytics';
import { alert } from './component/alert.listener.analytics';
import { articleGuides } from './component/article-guides.listener.analytics';
import { augmentedReality } from './component/augmented-reality.listener.analytics';
import { bathRenovation } from './component/bath-renovation.listener.analytics';
import { bulkFulFillment } from './component/bulk-fulfillment.listener.analytics';
import { buyItAgain } from './component/buy-it-again.listener.analytics';
import { buybox } from './component/buybox.listener.analytics';
import { calculator } from './component/calculator.listener.analytics';
import { carPicker } from './component/car-picker.listener.analytics';
import { cardMessaging } from './component/card-messaging.listener.analytics';
import { cartItem } from './component/cart-item.listener.analytics';
import { primaryFilters } from './component/primary-filters.listener.analytics';
import { catalogPromo } from './component/catalog-promo.listener.analytics';
import { categoryList } from './component/category-list.listener.analytics';
import { checkAvailability } from './component/check-availability.listener.analytics';
import { homeServicesCollection } from './component/collection-home-service.analytics';
import { collectionList } from './component/collection-list.listener.analytics';
import { creditCard } from './component/credit-cards.listener.analytics';
import { customerAccountNavigation } from './component/customer-account-navigation.listener.analytics';
import { dataSources } from './component/data-sources.listener.analytics';
import { decisionEngine } from './component/decision-engine.listener.analytics';
import { deliveryOptions } from './component/delivery-options.listener.analytics';
import { ddoReady } from './component/digital-data.listener.analytics';
import { dpd } from './component/dpd.listener.analytics';
import { educationalSummary } from './component/educational-summary.listener.analytics';
import { endcap } from './component/endcap.listener.analytics';
import { errorBoundary } from './component/error-boundary.listener.analytics';
import { fbr } from './component/fbr.listener.analytics';
import { fbt } from './component/fbt.listener.analytics';
import { flooringPicker } from './component/flooring-picker.listener.analytics';
import { filtersAndRefinements } from './component/filter-refinements.listener.analytics';
import { fulfillment } from './component/fulfillment.listener.analytics';
import { impressionProvider } from './component/impression-provider.listener.analytics';
import { instantCheckout } from './component/instant-checkout.listener.analytics';
import { instantCheckoutPageOverlay } from './component/instant-checkout-page.listener.analytics';
import { instantRecs } from './component/instant-recs.listener.analytics';
import { irg } from './component/irg.listener.analytics';
import { layoutManager } from './component/layout-manager.analytics';
import { articlesLeadGen, leadGeneration } from './component/lead-generation.listener.analytics';
import { listForms } from './component/list-forms.listener.analytics';
import { marketingContent } from './component/marketing-content.listener.analytics';
import { mediaGallery } from './component/media-gallery.listener.analytics';
import { militaryDiscount } from './component/military-discount.listener.analytics';
import { movingCalculator } from './component/moving-calculator.listener.analytics';
import { orderSample } from './component/order-sample.listener.analytics';
import { packagesMini } from './component/packages-mini.listener.analytics';
import { pagination } from './component/pagination.listener.analytics';
import { paintReorderResults } from './component/paint-reorder-results.listener.analytics';
import { partsAndServicesDrawer } from './component/parts-and-services-drawer.listner.analytics';
import { paymentMethodCard } from './component/payment-method-card.listener.analytics';
import { poJob } from './component/poJob.listener.analytics';
import { price } from './component/price.listener.analytics';
import { dualPath } from './component/product-add-ons.listener.analytics';
import { productDetails } from './component/product-details.listener.analytics';
import { productHotspots } from './component/product-hotspots.listener.analytics';
import { productList } from './component/product-list.listener.analytics';
import { productOverview } from './component/product-overview.listener.analytics';
import { productPodGroup } from './component/product-pod-group.listener.analytics';
import { productPodComposable } from './component/product-pod.composable.listener.analytics';
import { productPod } from './component/product-pod.listener.analytics';
import { productResults } from './component/product-results.listener.analytics';
import { productSpecifications } from './component/product-specifications.listener.analytics';
import { projectDetails } from './component/project-details.listener.analytics';
import { projectForms } from './component/project-forms.listener.analytics';
import { projectSummary } from './component/project-summary.listener.analytics';
import { promotionProducts } from './component/promotion-products.listener.analytics';
import { promotion } from './component/promotion.listener.analytics';
import { questionsAndAnswers } from './component/questions-and-answers.listener.analytics';
import { quickView } from './component/quick-view.listener.analytics';
import { quiz } from './component/quiz.listener.analytics';
import { quoteDetails } from './component/quote-details.listener.analytics';
import { ratingsReviews } from './component/ratings-reviews.listener.analytics';
import { recentPurchases } from './component/recent-purchases.listener.analytics';
import { recsAnalytics } from './component/recs.listener.analytics';
import { recsTntFallback } from './component/recs-tnt-fallback.listener.analytics';
import { relatedSearch } from './component/related-search.listener.analytics';
import { rentalIntent } from './component/rental-intent.listener.analytics';
import { rentalReservationForm } from './component/rental-reservation-form.listener.analytics';
import { routeListenerAnalytics } from './component/route.listener.analytics';
import { saveToFavoritesAnalytics } from './component/save-to-favorites.listener.analytics';
import { searchDropdown } from './component/search-dropdown.listener.analytics';
import { sharebelt } from './component/sharebelt.listener.analytics';
import { shippingAddressCard } from './component/shipping-address-card.listener.analytics';
import { sizeGuide } from './component/size-guide.listener.analytics';
import { sortDropdown } from './component/sort-dropdown.listener.analytics';
import { sortRefinements } from './component/sort-refinement.analytics';
import { specialBuyPromos } from './component/special-buy-promos.listener.analytics';
import { stashEvent } from './component/stash-event.listener';
import { stickyNav } from './component/sticky-nav.listener.analytics';
import { storePod } from './component/store-pod.listener.analytics';
import { storeSearch } from './component/store-search.listener.analytics';
import { superSku } from './component/super-sku.listener.analytics';
import { supplementalRecs } from './component/supplemental-recs.listener.analytics';
import { swapitem } from './component/swap-item.listener.analytics';
import { tabs } from './component/tabs.listener.analytics';
import { toolsMaterials } from './component/tools-materials.listener.analytics';
import { topCategories } from './component/top-categories.listener.analytics';
import { trackOOS } from './component/track-out-of-stock.listener.analytics';
import { trendingCategories } from './component/trending-categories.listener.analytics';
import { userListsCarousel } from './component/user-lists-carousel.listener.analytics';
import { videoPlayer } from './component/video-player.listener.analytics';
import { visualCategoryNav } from './component/visual-category-nav.listener.analytics';

import { page } from './experience/page';
import { seoExperience } from './experience/pages/seo-experience/seo-experience';
import { homeDepotWallet } from './component/home-depot-wallet.listener.analytics';
import { clearanceProduct } from './component/clearance-product.listener.analytics';
import { loyaltyBenefits } from './component/loyalty-benefits.listener.analytics';
import { proAllowance } from './component/pro-allowance.listener.analytics';

const ThdAnalytics = {
  init: () => {
    if (typeof window === 'undefined') return;

    if (!window.THD_EVENT_BUS) {
      window.THD_EVENT_BUS = window.LIFE_CYCLE_EVENT_BUS;
    }

    try {
      // priority inits - DO NOT change the order unless needed
      seoExperience();
      page(); // call experience analytics
      routeListenerAnalytics();
      dataSources();
      ddoReady();

      // alphabetized inits
      addToCartAnalytics();
      addToQuote();
      alert();
      aisleBay();
      articleGuides();
      articlesLeadGen();
      augmentedReality();
      bathRenovation();
      bulkFulFillment();
      quickAddToCartLocation();
      quickAddToCartClick();
      buybox();
      buyItAgain();
      calculator();
      carPicker();
      homeServicesCollection();
      cardMessaging();
      cartItem();
      catalogPromo();
      categoryList();
      checkAvailability();
      clearanceProduct();
      collectionList();
      creditCard();
      customerAccountNavigation();
      decisionEngine();
      deliveryOptions();
      dpd();
      educationalSummary();
      dualPath();
      endcap();
      errorBoundary();
      fbr();
      fbt();
      filtersAndRefinements();
      flooringPicker();
      fulfillment();
      homeDepotWallet();
      impressionProvider();
      instantCheckout();
      instantCheckoutPageOverlay();
      instantRecs();
      irg();
      layoutManager();
      leadGeneration();
      listForms();
      loyaltyBenefits();
      marketingContent();
      mediaGallery();
      militaryDiscount();
      movingCalculator();
      orderSample();
      packagesMini();
      pagination();
      paintReorderResults();
      partsAndServicesDrawer();
      paymentMethodCard();
      poJob();
      price();
      productDetails();
      productHotspots();
      productList();
      productOverview();
      productPodComposable();
      productPod();
      productPodGroup();
      productResults();
      projectDetails();
      projectForms();
      projectSummary();
      primaryFilters();
      productSpecifications();
      promotion();
      promotionProducts();
      questionsAndAnswers();
      quickView();
      quiz();
      quoteDetails();
      ratingsReviews();
      recentPurchases();
      recsAnalytics();
      recsTntFallback();
      relatedSearch();
      rentalIntent();
      rentalReservationForm();
      saveToFavoritesAnalytics();
      searchDropdown();
      sharebelt();
      shippingAddressCard();
      sizeGuide();
      sortDropdown();
      sortRefinements();
      specialBuyPromos();
      stashEvent();
      stickyNav();
      storePod();
      storeSearch();
      superSku();
      supplementalRecs();
      swapitem();
      tabs();
      toolsMaterials();
      topCategories();
      trackOOS();
      trendingCategories();
      userListsCarousel();
      videoPlayer();
      visualCategoryNav();
      proAllowance();
    } catch (err) {
      console.log(err);
    }
  },
};
export { ThdAnalytics };
